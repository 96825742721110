
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class industry extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }
    tb = {
      page: 1,
      pageSize: 10
    }
    pageChange({ currentPage, pageSize }) {
      this.tb.page = currentPage
      this.tb.pageSize = pageSize
    }
  typeOptions = [
    { label: '政府单位', value: 3 },
    { label: '高校', value: 4 },
    { label: '科研机构', value: 5 },
    { label: '科技服务机构', value: 6 },
    { label: '医院', value: 7 },
    { label: '社会团体', value: 8 },
    { label: '其他事业单位', value: 9 },
    { label: '军队武警', value: 10 },
    { label: '国企', value: 11 },
    { label: '民企', value: 12 },
    { label: '外企', value: 13 },
    { label: '混合所有制企业', value: 14 },
    { label: '境外机构', value: 15 },
    { label: '其他组织', value: 16 }
  ]
  TableA_all_data = []
  TableA_total = 0
  TableA_search_data = { name: '', type: 4 }
  rule_TableA_search_data = {}
  TableA_add_dialog = { open: false, title: '新增' }
  TableA_add_data = { type: 4, code: '', name: '' }
  rule_TableA_add_data = {
    type: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    name: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }]
  }
  TableA_del_dialog = { open: false, title: '删除' }
  TableA_del_data = { id: 0 }
  TableA_edit_dialog = { open: false, title: '编辑' }
  TableA_edit_data = { id: 0, type: 4, code: '', name: '' }
  rule_TableA_edit_data = {
    parent_id: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    name: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }]
  }
  TableA_search() {
    this.TableA_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize, [this.TableA_search_data.type])
  }
  industries: any[] = []
  TableA_get_all_data(
    offset = 0,
    length = 10,
    type = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
  ) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      name: this.TableA_search_data.name,
      type
    }
    Api.http_industryTableA0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.TableA_all_data = res.data.data
        this.TableA_total = res.data.total
      }
    })
  }
  TableA_add_init(row) {
    this.get_industries(row.id)
    this.TableA_add_dialog.open = true
  }
  TableA_add_cancle() {
    this.TableA_add_dialog.open = false
  }
  TableA_add_ok() {
    this.$refs.ref_TableA_add_data.validate((valid) => {
      if (valid) {
        const postData = {
          type: this.TableA_add_data.type,
          code: this.TableA_add_data.code,
          name: this.TableA_add_data.name
        }
        Api.http_industryTableA1(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.TableA_add_dialog.open = false
            this.TableA_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  TableA_del_init(row) {
    this.TableA_del_data.id = row.id
    this.TableA_del_dialog.open = true
  }
  TableA_del_cancle() {
    this.TableA_del_dialog.open = false
  }
  TableA_del_ok() {
    const postData = {
      id: this.TableA_del_data.id
    }
    Api.http_industryTableA2(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.TableA_del_dialog.open = false
        this.TableA_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
        this.$message.success(JSON.stringify(res.message))
      }
    })
  }
  TableA_edit_init(row) {
    this.get_industries(row.id)
    Api.http_industryTableAget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.TableA_edit_data.id = row.id
        this.TableA_edit_data.type = res.data.type
        this.TableA_edit_data.code = res.data.code
        this.TableA_edit_data.name = res.data.name
        this.TableA_edit_dialog.open = true
      }
    })
  }
  TableA_edit_cancle() {
    this.TableA_edit_dialog.open = false
  }
  TableA_edit_ok() {
    this.$refs.ref_TableA_edit_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.TableA_edit_data.id,
          type: this.TableA_edit_data.type,
          code: this.TableA_edit_data.code,
          name: this.TableA_edit_data.name
        }
        Api.http_industryTableA5(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.TableA_edit_dialog.open = false
            this.TableA_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  TableA_init() {
    this.TableA_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
  }
  get_industries(id = -1) {
    Api.http_getCategoryList({ type: 1, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.industries = res.data.data
        }
      }
    )
  }
  created() {
    this.TableA_init()
  }
}
